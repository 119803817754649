import adjust from "ramda/src/adjust";
import times from "ramda/src/times";
import React, {
  FormEvent,
  useCallback,
  useEffect,
  useMemo,
  useState
} from "react";
import picMesure from "../../assets/mesure.png";
import { QuestionTooltip } from "../../components/question-tooltip";
import { fetchUnitSystems } from "../../model/main-service";
import {
  getQuestion,
  getComment,
  getStepForLevel,
  getStepList,
  StepViewComponent
} from "../../model/steps/steps-model";
import styles from "../../styles/modules/project-mesure.module.scss";
import { usePromise } from "../../utils/react-utils";
import { Tra } from "../tra";
import {
  getAllDescendants,
  getSubpartSubsteps,
  getSubsetDataWParentsData,
  getSubstep,
  getSubstepsForProject
} from "../../model/substeps/substeps-model";
import { AuthenticationState } from "../../model/authentication/authentication-model";
import { connect } from "react-redux";
import { MainState } from "../../model/main-model";
import { flatten } from "ramda";

export type AreaZoneToCover = {
  areaZoneToCover: number;
  widthZoneToCover: number;
  heigthZoneToCover: number;
}[];

export type AreaZoneToRetrive = {
  areaZoneToRetrive: number;
  widthZoneToRetrive: number;
  heigthZoneToRetrive: number;
}[];

const mapStateToProps = (state: MainState) => ({
  currentUnitSystem: state.authentication.currentUnitSystem
});

const Component: StepViewComponent = ({
  substepId,
  substeps,
  steps,
  selectedOptions,
  onOptionsChange,
  currentUnitSystem
}) => {
  const parentSubstep = getSubstep(substepId, substeps);
  const msData =
    parentSubstep &&
    parentSubstep.selectedOptions &&
    parentSubstep.selectedOptions[0]
      ? parentSubstep.selectedOptions[0].data
      : null;

  let initialState = {
    allAreaZonesToCover: [
      {
        areaZoneToCover: 0,
        widthZoneToCover: 0,
        heigthZoneToCover: 0
      }
    ],
    allAreaZonesToRetrive: [
      {
        areaZoneToRetrive: 0,
        widthZoneToRetrive: 0,
        heigthZoneToRetrive: 0
      }
    ]
  };

  if (msData) {
    for (let dataIn of msData.in.filter(
      item => item.length !== 0 && item.height !== 0
    )) {
      initialState.allAreaZonesToCover.push({
        areaZoneToCover: Math.round(10 * dataIn.length * dataIn.height) / 10,
        widthZoneToCover: dataIn.length,
        heigthZoneToCover: dataIn.height
      });
    }
    if (initialState.allAreaZonesToCover.length > 1) {
      initialState.allAreaZonesToCover.shift();
    }
    for (let dataOut of msData.out.filter(
      item => item.length !== 0 && item.height !== 0
    )) {
      initialState.allAreaZonesToRetrive.push({
        areaZoneToRetrive:
          Math.round(10 * dataOut.length * dataOut.height) / 10,
        widthZoneToRetrive: dataOut.length,
        heigthZoneToRetrive: dataOut.height
      });
    }
    if (initialState.allAreaZonesToRetrive.length > 1) {
      initialState.allAreaZonesToRetrive.shift();
    }
  }

  const [formState, updateFormState] = useState(initialState);

  const { allAreaZonesToCover, allAreaZonesToRetrive } = formState;
  const nmbrOfCoveredZones = allAreaZonesToCover.length;
  const nmbrOfRetrivedZones = allAreaZonesToRetrive.length;

  const setAreaZonesToCover = (data: typeof initialState.allAreaZonesToCover) =>
    updateFormState({ ...formState, allAreaZonesToCover: data });

  const setAreaZonesToRetrive = (
    data: typeof initialState.allAreaZonesToRetrive
  ) => updateFormState({ ...formState, allAreaZonesToRetrive: data });
  // const [nmbrOfCoveredZones, setCoveredZone] = useState(1);
  // const [nmbrOfRetrivedZones, setRetrivedZone] = useState(1);

  // const [unitSys, setUnitSys] = useState("Métrique");
  const [unitSystems] = usePromise(fetchUnitSystems, [], []);
  const [unitsFormState, setUnitsFormState] = useState<
    {
      checked: boolean;
      data: typeof unitSystems[0];
    }[]
  >([]);

  useEffect(() => {
    updateFormState(initialState);
  }, [initialState, substepId]);

  useEffect(() => {
    setUnitsFormState(
      unitSystems.map((sys, index) => ({
        checked: sys["@id"] === currentUnitSystem,
        data: sys
      }))
    );
  }, [currentUnitSystem, unitSystems]);

  const selectedUnit = useMemo(() => {
    const checked = unitsFormState.find(entry => entry.checked);
    return checked ? checked.data : null;
  }, [unitsFormState]);

  const isMetric = useMemo(
    () =>
      selectedUnit
        ? unitSystems.find(
            system => selectedUnit["@id"] === system["@id"] && system.isDefault
          )
        : true,
    [selectedUnit, unitSystems]
  );

  // const [allAreaZonesToCover, setAreaZonesToCover] = useState<AreaZoneToCover>([
  //   { areaZoneToCover: 0, widthZoneToCover: 0, heigthZoneToCover: 0 }
  // ]);
  // const [allAreaZonesToRetrive, setAreaZonesToRetrive] = useState<
  //   AreaZoneToRetrive
  // >([
  //   {
  //     areaZoneToRetrive: 0,
  //     widthZoneToRetrive: 0,
  //     heigthZoneToRetrive: 0
  //   }
  // ]);

  const isWallpaper = () => {
    const substepMO = getSubsetDataWParentsData(
      substepId ?? "",
      substeps
    ).filter(item => item?.type === "MO");
    return substepMO[0] && substepMO[0].value !== null;
  };

  /**************************************\\\\\\Calcul Total Zones Covered//////********************************************* */

  const calculateTotalCoveredZone = useCallback(
    (toAdd: AreaZoneToCover, toRemove: AreaZoneToRetrive) => {
      let sumOfZonesToCover = toAdd
        .map(v => v.areaZoneToCover)
        .reduce((sum, current) => sum + Number(current), 0);

      let sumOfZonesToRetrive = toRemove
        .map(v => v.areaZoneToRetrive)
        .reduce((sum, current) => sum + Number(current), 0);

      let total = sumOfZonesToCover - sumOfZonesToRetrive;

      return total;
    },
    []
  );

  useEffect(() => {
    if (!selectedUnit) {
      return;
    }
    onOptionsChange([
      {
        id: "MS",
        type: "MS",
        label: "mesure",
        sortOrder: null,
        value: String(
          calculateTotalCoveredZone(allAreaZonesToCover, allAreaZonesToRetrive)
        ),
        data: {
          total: calculateTotalCoveredZone(
            allAreaZonesToCover,
            allAreaZonesToRetrive
          ),
          in: allAreaZonesToCover.map(v => ({
            length: v.widthZoneToCover,
            height: v.heigthZoneToCover
          })),
          out: allAreaZonesToRetrive.map(v => ({
            length: v.widthZoneToRetrive,
            height: v.heigthZoneToRetrive
          })),
          unitSystem: selectedUnit["@id"]
          // allAreaZonesToCover:allAreaZonesToCover,
          // allAreaZonesToRetrive:allAreaZonesToRetrive
        }
      }
    ]);
  }, [selectedUnit, allAreaZonesToCover, allAreaZonesToRetrive, onOptionsChange, calculateTotalCoveredZone]);

  const handleInputUnitMesure = (event: FormEvent) => {
    // setUnitSys(e.target.value);
    setUnitsFormState(
      unitsFormState.map(entry => ({
        ...entry,
        checked: (event.target as HTMLFormElement).value === entry.data["@id"]
      }))
    );
  };

  const deleteZoneCovered = (e: any) => {
    let temp = [...allAreaZonesToCover];
    temp.splice(e.target.id, 1);
    setAreaZonesToCover(temp);
    // console.log("-------->>",allAreaZonesToCover.splice(e.target.id))
  };

  const deleteZoneRetrived = (e: any) => {
    let temp = [...allAreaZonesToRetrive];
    temp.splice(e.target.id, 1);
    setAreaZonesToRetrive(temp);
    // console.log("-------->>",allAreaZonesToCover.splice(e.target.id))
  };

  /************************\\\\\\\Adding Covered Zones////////*************************************/

  const addZonesCovered = () => {
    var ZonesCovered = [];
    for (var i = 0; i < allAreaZonesToCover.length; i++) {
      ZonesCovered.push(
        <div
          className={`${styles["zone"]} row w-100 `}
          key={`calcul-zones-couve${i}`}
        >
          <div className="col-3">
            <input
              min={0}
              max={1000}
              step={0.01}
              type="number"
              name="areaZoneToCover"
              id={`${i}`}
              value={allAreaZonesToCover[i].areaZoneToCover.toString()}
              onChange={e => {
                onChangeAreaCoveredZones(e);
              }}
            />
            <label>{isMetric ? "m²" : "sq ft"}</label>
          </div>
          <div className="col-1">
            <label className={styles["calcul-zone-or-label"]}>OU</label>
          </div>
          <div className="col-3">
            <input
              min={0}
              max={1000}
              step={0.01}
              type="number"
              name="widthZoneToCover"
              id={`${i}`}
              value={allAreaZonesToCover[i].widthZoneToCover.toString()}
              onChange={e => {
                onChangeWidthCoveredZones(e);
              }}
            />
            <label htmlFor={`${i}`}>{isMetric ? "m" : "ft"}</label>
          </div>
          <div className="col-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.852"
              height="20.852"
              viewBox="0 0 20.852 20.852"
            >
              <path
                id="ic_close_24px"
                d="M25.852,7.1,23.752,5l-8.326,8.326L7.1,5,5,7.1l8.326,8.326L5,23.752l2.1,2.1,8.326-8.326,8.326,8.326,2.1-2.1-8.326-8.326Z"
                transform="translate(-5 -5)"
                fill="#e6786b"
              />
            </svg>
          </div>
          <div className="col-3">
            <input
              min={0}
              max={1000}
              step={0.01}
              type="number"
              name="heigthZoneToCover"
              id={`${i}`}
              value={allAreaZonesToCover[i].heigthZoneToCover.toString()}
              onChange={e => {
                onChangeHeightCoveredZones(e);
              }}
            />
            <label htmlFor={`${i}`}>{isMetric ? "m" : "ft"}</label>
          </div>
          <div className="col-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22.726"
              height="29.22"
              viewBox="0 0 22.726 29.22"
              name="suppZoneCovred"
              className={styles.trash}
              id={`${i}`}
              onClick={e => deleteZoneCovered(e)}
            >
              <path
                id="ic_delete_forever_24px"
                d="M6.623,28.973A3.256,3.256,0,0,0,9.87,32.22H22.856A3.256,3.256,0,0,0,26.1,28.973V9.493H6.623Zm3.993-11.558,2.289-2.289,3.458,3.441L19.8,15.126l2.289,2.289-3.441,3.441L22.094,24.3,19.8,26.587l-3.441-3.441-3.441,3.441L10.633,24.3l3.441-3.441ZM22.045,4.623,20.422,3H12.3L10.682,4.623H5V7.87H27.726V4.623Z"
                transform="translate(-5 -3)"
                fill="#e6786b"
              />
            </svg>
          </div>
        </div>
      );
    }
    return ZonesCovered;
  };

  const addingCoverZones = () => {
    let temp = allAreaZonesToCover;
    temp.push({
      areaZoneToCover: 0,
      widthZoneToCover: 0,
      heigthZoneToCover: 0
    });
    setAreaZonesToCover(temp);
  };

  const onChangeAreaCoveredZones = (e: any) => {
    setAreaZonesToCover(
      adjust(
        Number(e.target.id),
        () => ({
          areaZoneToCover:
            Number(e.target.value) > e.target.max
              ? e.target.max
              : e.target.value.replace(/,/g, "."),
          widthZoneToCover: Number(Math.sqrt(e.target.value).toFixed(2)),
          heigthZoneToCover: Number(Math.sqrt(e.target.value).toFixed(2))
        }),
        allAreaZonesToCover
      )
    );
  };

  const onChangeWidthCoveredZones = (e: any) => {
    let tempAreaZone =
      Math.round(
        100 *
          Number(e.target.value) *
          Number(allAreaZonesToCover[e.target.id].heigthZoneToCover)
      ) / 100;
    setAreaZonesToCover(
      adjust(
        Number(e.target.id),
        () => ({
          areaZoneToCover: tempAreaZone,
          heigthZoneToCover: Number(
            Number(allAreaZonesToCover[e.target.id].heigthZoneToCover).toFixed(
              2
            )
          ),
          widthZoneToCover: /*Number(Number(e.target.value.replace(/,/g, ".")).toFixed(2))*/e.target.value.replace(/,/g, ".")
        }),
        allAreaZonesToCover
      )
    );
  };

  const onChangeHeightCoveredZones = (e: any) => {
    let tempAreaZone =
      Math.round(
        100 *
          Number(e.target.value) *
          Number(allAreaZonesToCover[e.target.id].widthZoneToCover)
      ) / 100;
    setAreaZonesToCover(
      adjust(
        Number(e.target.id),
        () => ({
          areaZoneToCover: tempAreaZone,
          heigthZoneToCover: /*Number(Number(e.target.value).toFixed(2))*/e.target.value.replace(/,/g, "."),
          widthZoneToCover: Number(
            Number(allAreaZonesToCover[e.target.id].widthZoneToCover).toFixed(2)
          )
        }),
        allAreaZonesToCover
      )
    );
  };
  /************************\\\\\\\Adding Retrived Zones////////*************************************/

  const addZonesretrived = () => {
    var Zonesretrived = [];
    for (var i = 0; i < allAreaZonesToRetrive.length; i++) {
      Zonesretrived.push(
        <div
          className={`${styles["zone"]} row w-100 `}
          key={`calcul-zones-ret${i}`}
        >
          <div className="col-3">
            <input
              min={0}
              max={1000}
              step={0.01}
              id={`${i}`}
              type="number"
              name="areaZoneToRetrive"
              value={allAreaZonesToRetrive[i].areaZoneToRetrive}
              onChange={e => {
                onChangeAreaRetrivedZones(e);
              }}
            />
            <label htmlFor={`${i}`}>{isMetric ? "m²" : "sq ft"}</label>
          </div>
          <div className="col-1">
            <label className={styles["calcul-zone-or-label"]}>OU</label>
          </div>
          <div className="col-3">
            <input
              id={`${i}`}
              min={0}
              max={1000}
              step={0.01}
              type="number"
              name="widthZoneToRetrive"
              value={allAreaZonesToRetrive[i].widthZoneToRetrive}
              onChange={e => {
                onChangeWidthRetrivedZones(e);
              }}
            />
            <label>{isMetric ? "m" : "ft"}</label>
          </div>
          <div className="col-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20.852"
              height="20.852"
              viewBox="0 0 20.852 20.852"
            >
              <path
                id="ic_close_24px"
                d="M25.852,7.1,23.752,5l-8.326,8.326L7.1,5,5,7.1l8.326,8.326L5,23.752l2.1,2.1,8.326-8.326,8.326,8.326,2.1-2.1-8.326-8.326Z"
                transform="translate(-5 -5)"
                fill="#e6786b"
              />
            </svg>
          </div>
          <div className="col-3">
            <input
              id={`${i}`}
              min={0}
              max={1000}
              step={0.01}
              type="number"
              name="heigthZoneToRetrive"
              value={allAreaZonesToRetrive[i].heigthZoneToRetrive}
              onChange={e => {
                onChangeHeightRetrivedZones(e);
              }}
            />
            <label htmlFor={`${i}`}>{isMetric ? "m" : "ft"}</label>
          </div>
          <div className="col-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="22.726"
              height="29.22"
              viewBox="0 0 22.726 29.22"
              name="suppZoneCovred"
              className={styles.trash}
              id={`${i}`}
              onClick={e => deleteZoneRetrived(e)}
            >
              <path
                id="ic_delete_forever_24px"
                d="M6.623,28.973A3.256,3.256,0,0,0,9.87,32.22H22.856A3.256,3.256,0,0,0,26.1,28.973V9.493H6.623Zm3.993-11.558,2.289-2.289,3.458,3.441L19.8,15.126l2.289,2.289-3.441,3.441L22.094,24.3,19.8,26.587l-3.441-3.441-3.441,3.441L10.633,24.3l3.441-3.441ZM22.045,4.623,20.422,3H12.3L10.682,4.623H5V7.87H27.726V4.623Z"
                transform="translate(-5 -3)"
                fill="#e6786b"
              />
            </svg>
          </div>
        </div>
      );
    }
    return Zonesretrived;
  };

  const addingRetrivedZones = () => {
    let temp = allAreaZonesToRetrive;
    temp.push({
      areaZoneToRetrive: 0,
      widthZoneToRetrive: 0,
      heigthZoneToRetrive: 0
    });
    setAreaZonesToRetrive(temp);
  };

  const subtractRetrivedZones = () => {
    setAreaZonesToRetrive(
      allAreaZonesToRetrive.slice(0, allAreaZonesToRetrive.length - 1)
    );
  };

  const setRetrivedZone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetNumber = Number(event.target.value);
    const diff = targetNumber - allAreaZonesToRetrive.length;

    setAreaZonesToRetrive([
      ...allAreaZonesToRetrive.slice(
        0,
        Math.min(allAreaZonesToRetrive.length, targetNumber)
      ),
      ...times(
        () => ({ ...initialState.allAreaZonesToRetrive[0] }),
        Math.max(0, diff)
      )
    ]);
  };

  const setCoveredZone = (event: React.ChangeEvent<HTMLInputElement>) => {
    const targetNumber = Number(event.target.value);
    const diff = targetNumber - allAreaZonesToCover.length;

    setAreaZonesToCover([
      ...allAreaZonesToCover.slice(
        0,
        Math.min(allAreaZonesToCover.length, targetNumber)
      ),
      ...times(
        () => ({ ...initialState.allAreaZonesToCover[0] }),
        Math.max(0, diff)
      )
    ]);
  };

  const subtractCoverZones = () => {
    setAreaZonesToCover(
      allAreaZonesToCover.slice(0, allAreaZonesToCover.length - 1)
    );
  };

  const onChangeAreaRetrivedZones = (e: any) => {
    setAreaZonesToRetrive(
      adjust(
        Number(e.target.id),
        () => ({
          areaZoneToRetrive:
            Number(e.target.value) > e.target.max
              ? e.target.max
              : e.target.value.replace(/,/g, "."),
          heigthZoneToRetrive: Number(Math.sqrt(e.target.value).toFixed(2)),
          widthZoneToRetrive: Number(Math.sqrt(e.target.value).toFixed(2))
        }),
        allAreaZonesToRetrive
      )
    );
  };

  const onChangeWidthRetrivedZones = (e: any) => {
    let tempAreaZone =
      Math.round(
        100 *
          Number(e.target.value) *
          Number(allAreaZonesToRetrive[e.target.id].heigthZoneToRetrive)
      ) / 100;
    setAreaZonesToRetrive(
      adjust(
        Number(e.target.id),
        () => ({
          areaZoneToRetrive: tempAreaZone,
          heigthZoneToRetrive: Number(
            Number(
              allAreaZonesToRetrive[e.target.id].heigthZoneToRetrive
            ).toFixed(2)
          ),
          widthZoneToRetrive: /*Number(Number(e.target.value).toFixed(2))*/e.target.value.replace(/,/g, ".")
        }),
        allAreaZonesToRetrive
      )
    );
  };

  const onChangeHeightRetrivedZones = (e: any) => {
    let tempAreaZone =
      Math.round(
        100 *
          Number(e.target.value) *
          Number(allAreaZonesToRetrive[e.target.id].widthZoneToRetrive)
      ) / 100;
    setAreaZonesToRetrive(
      adjust(
        Number(e.target.id),
        () => ({
          areaZoneToRetrive: tempAreaZone,
          heigthZoneToRetrive: /*Number(Number(e.target.value).toFixed(2))*/e.target.value.replace(/,/g, "."),
          widthZoneToRetrive: Number(
            Number(
              allAreaZonesToRetrive[e.target.id].widthZoneToRetrive
            ).toFixed(2)
          )
        }),
        allAreaZonesToRetrive
      )
    );
  };
  /*****************************************************************************************************************/
  // useEffect(() => {
  //   onOptionsChange([{type:"MS",data:{allAreaZonesToCover:allAreaZonesToCover,allAreaZonesToRetrive:allAreaZonesToRetrive} }]);
  // },  [allAreaZonesToCover,allAreaZonesToRetrive]);

  return (
    <form>
      <div className={styles["project-mesure"]}>
        <div className={styles["mesure-header"]}>
          <h3 className={`${styles["projectmesure"]} projectmesure`}>
            <div dangerouslySetInnerHTML={{ __html: getQuestion(getSubstep(substepId, substeps).stepLevel, steps) }} />
            <QuestionTooltip
              tooltipText={getComment(
                getSubstep(substepId, substeps).stepLevel,
                steps
              )}
              questionClassName={`${styles["projectmesure_icoquestion"]} ico-question`}
            />
          </h3>
          {isWallpaper() ? (
            <h5>
              <Tra code="txt-measure-wallpaper" />
            </h5>
          ) : (
            ""
          )}
        </div>
        <div className={styles["mesure-total"]}>
          <div></div>
          <div>
            <img
              src={picMesure}
              className={styles.projectmesure_pic_mesure}
              alt=""
            />
            <label className={styles.projectmesure_label}>
              <Tra code="lbl-form-ms-total-area" />
            </label>
          </div>{" "}
          <div className={styles["surface-total"]}>
            {" "}
            <div className={styles["total"]}>
              {Number(
                calculateTotalCoveredZone(
                  allAreaZonesToCover,
                  allAreaZonesToRetrive
                )
              ).toFixed(2)}
            </div>{" "}
            <div className={styles["mesure"]}>
              {" "}
              {isMetric ? "m²" : "sq ft"}{" "}
            </div>{" "}
          </div>{" "}
        </div>
        <div className={styles["mesure-unit"]}>
          {" "}
          <label className={styles.projectmesure_label}>
            <Tra code="lbl-form-ms-unit-system" />
          </label>
          <div className={styles["ui-unit-choice-mesure"]}>
            {unitsFormState.map(entry => (
              <div key={entry.data["@id"]}>
                <input
                  type="radio"
                  id={entry.data["@id"]}
                  name="unitSystem"
                  value={entry.data["@id"]}
                  checked={entry.checked}
                  onChange={e => {
                    handleInputUnitMesure(e);
                  }}
                ></input>
                <label htmlFor={entry.data["@id"]}>{entry.data["label"]}</label>
              </div>
            ))}
          </div>{" "}
        </div>
      </div>
      <div className="row">
        <div className={`${styles["projectmesure_zone"]} col-12 col-xl-6`}>
          <div className=" w-100">
            <div className={`${styles["projectmesure_zone_input"]} d-flex `}>
              <div>
                <label>
                  <Tra code="lbl-form-ms-zones-in" />
                </label>
              </div>{" "}
              <input
                type="number"
                name="nmbrOfZones"
                value={nmbrOfCoveredZones}
                onChange={setCoveredZone}
              />{" "}
              <input
                type="button"
                name="AddZones"
                onClick={addingCoverZones}
                value="+"
              />
              {allAreaZonesToCover.length > 1 ? (
                <input
                  type="button"
                  name="AddZones"
                  onClick={subtractCoverZones}
                  value="-"
                />
              ) : null}
            </div>
          </div>
          <div className=" w-100">
            <div
              className={`${styles["projectmesure_zone_surface"]} d-flex row`}
            >
              <div className="col-3">
                <label>
                  <Tra code="lbl-form-ms-area" />
                </label>
              </div>
              <div className="col-1">
                <label>
                  <Tra code="lbl-form-ms-or" />
                </label>
              </div>
              <div className="col-3">
                <label>
                  <Tra code="lbl-form-ms-width" />
                </label>
              </div>
              <div className="col-1"></div>
              <div className="col-3">
                <label>
                  <Tra code="lbl-form-ms-height" />
                </label>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
          <div className="w-100">
            <div className="d-flex row">{addZonesCovered()}</div>
          </div>
        </div>
        <div className={`${styles["projectmesure_zone"]} col-12 col-xl-6`}>
          <div className=" w-100">
            <div className={`${styles["projectmesure_zone_input"]} d-flex `}>
              <div>
                <label>
                  <Tra code="lbl-form-ms-zones-out" />
                </label>
              </div>
              <input
                type="number"
                name="nmbrOfZones"
                min="0"
                value={nmbrOfRetrivedZones}
                onChange={setRetrivedZone}
              />{" "}
              <input
                type="button"
                name="AddZones"
                onClick={addingRetrivedZones}
                value="+"
              />
              {allAreaZonesToRetrive.length > 1 ? (
                <input
                  type="button"
                  name="AddZones"
                  onClick={subtractRetrivedZones}
                  value="-"
                />
              ) : null}
            </div>
          </div>
          <div className=" w-100">
            <div
              className={`${styles["projectmesure_zone_surface"]} d-flex row`}
            >
              <div className="col-3">
                <label>
                  <Tra code="lbl-form-ms-area" />
                </label>
              </div>
              <div className="col-1">
                <label>
                  <Tra code="lbl-form-ms-or" />
                </label>
              </div>
              <div className="col-3">
                <label>
                  <Tra code="lbl-form-ms-width" />
                </label>
              </div>
              <div className="col-1"></div>
              <div className="col-3">
                <label>
                  <Tra code="lbl-form-ms-height" />
                </label>
              </div>
              <div className="col-1"></div>
            </div>
          </div>
          <div className="w-100">
            <div className="d-flex row">{addZonesretrived()}</div>
          </div>
        </div>
      </div>
    </form>
  );
};

export const MSStepView = connect(mapStateToProps)(Component);
